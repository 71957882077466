import { get, toNumber, mapKeys, assign } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import {
  useDevice,
  useDeviceStateHistory,
  WidgetDataRangeEnum,
} from '@portals/api/organizations';
import {
  SplineChartWidget,
  SplineChartWidgetFormType,
} from '@portals/device-widgets';

import { SPLINE_CHART_COLORS } from '../device-widgets.constants';
import { WidgetProps } from '../device-widgets.types';

export function SplineChartWidgetWrapper({
  widget,
  deviceId,

  isDeviceOffline,
  lastUpdateTimestamp,
}: WidgetProps<SplineChartWidgetFormType>) {
  const [range, setRange] = useState<WidgetDataRangeEnum>(
    WidgetDataRangeEnum.Day
  );
  const deviceHistory = useDeviceStateHistory({
    deviceId,
    widgetDataRange: range,
    queryOptions: {
      enabled: !!deviceId,
      staleTime: 0,
    },
  });

  const device = useDevice(deviceId);

  const fields = widget?.config?.fields;

  const {
    name,
    measurements,
    scale_type,
    number_format,
    num_of_decimals,
    measurement_type,
    path,
  } = fields ?? {};

  const dynamicMeasurements = useMemo(() => {
    if (!path || !device.isFetched) return [];

    const measurements = get(path, device.data) || [];

    return measurements?.map((measurement, index) => {
      const mappedMeasurements = mapKeys((key) => {
        return key === 'name' ? 'label' : key;
      }, measurement);

      return assign(mappedMeasurements, {
        key: uuid(),
        color: SPLINE_CHART_COLORS[index],
      });
    });
  }, [device.data, device.isFetched, path]);

  const data = useMemo(() => {
    if (!deviceHistory.isFetched) return [];

    const measurementsByType =
      measurement_type === 'static' ? measurements : dynamicMeasurements;

    return deviceHistory.data?.map(([state, timestamp]) => ({
      timestamp,
      ...measurementsByType?.reduce((acc, measurement) => {
        acc[measurement.telemetry] = toNumber(state[measurement.telemetry]);

        return acc;
      }, {}),
    }));
  }, [
    deviceHistory.data,
    deviceHistory.isFetched,
    dynamicMeasurements,
    measurement_type,
    measurements,
  ]);

  return (
    <SplineChartWidget
      title={name}
      measurements={
        measurement_type === 'static' ? measurements : dynamicMeasurements
      }
      data={data}
      isLoading={deviceHistory.isLoading}
      range={range}
      id={widget.id}
      setRange={setRange}
      scaleType={scale_type}
      numberFormat={number_format}
      numOfDecimals={num_of_decimals}
      isDeviceOffline={isDeviceOffline}
      lastUpdateTimestamp={lastUpdateTimestamp}
    />
  );
}
