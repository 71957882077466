import {
  ActionIcon,
  Alert,
  AlertProps,
  Anchor,
  Box,
  Button,
  createStyles,
  Group,
  Input,
  SegmentedControl,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { motion } from 'framer-motion';
import React from 'react';
import { v4 as uuid } from 'uuid';

import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';
import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';

import { SplineChartWidgetFormType } from './spline-chart-form.types';
import { NumberFormatSelector } from '../../common/NumberFormatSelector';
import { PopoverColorSelector } from '../../common/PopoverColorSelector';
import {
  MeasurementFormType,
  OnAddCustomColorFn,
  WidgetColorType,
} from '../../widgets.types';

export interface SplineChartWidgetFormProps {
  form: UseFormReturnType<SplineChartWidgetFormType>;
  colors: Array<WidgetColorType> | undefined;
  onAddCustomColor: OnAddCustomColorFn | undefined;
}

export function SplineChartWidgetForm({
  form,
  colors,
  onAddCustomColor,
}: SplineChartWidgetFormProps) {
  const { classes } = useStyles();

  return (
    <Stack spacing="xl">
      <Stack>
        <Text size="sm" color="gray.9">
          General
        </Text>

        <TextInput
          {...form.getInputProps('name')}
          autoFocus
          data-testid="spline-chart-title-name-input"
          data-autofocus
          label="Title"
          required
        />

        <Input.Wrapper>
          <Stack spacing={0}>
            <Input.Label>Scale Type</Input.Label>

            <SegmentedControl
              data={[
                {
                  label: 'Linear',
                  value: 'linear',
                },
                {
                  label: 'Logarithmic',
                  value: 'log',
                },
              ]}
              value={form.values.scale_type}
              onChange={(value: 'linear' | 'log') =>
                form.setFieldValue('scale_type', value)
              }
            />
          </Stack>
        </Input.Wrapper>

        <NumberFormatSelector
          format={form.values.number_format}
          numOfDecimals={form.values.num_of_decimals}
          onFormatChange={(format) =>
            form.setFieldValue('number_format', format)
          }
          onNumOfDecimalsChange={(numOfDecimals) =>
            form.setFieldValue('num_of_decimals', numOfDecimals)
          }
        />
      </Stack>

      <Stack>
        <Text size="sm" color="gray.9">
          Measurements
        </Text>

        <SegmentedControl
          data={[
            {
              label: 'Static',
              value: 'static',
            },
            {
              label: 'Dynamic',
              value: 'dynamic',
            },
          ]}
          value={form.values.measurement_type}
          onChange={(value: MeasurementFormType) =>
            form.setFieldValue('measurement_type', value)
          }
        />
      </Stack>

      {form.values.measurement_type === 'static' ? (
        <>
          <motion.div layout="position">
            <Stack>
              {form.values.measurements.map((measurement, index) => (
                <Group w="100%" key={measurement.key}>
                  <Input.Wrapper pt={4}>
                    <Input.Label />

                    <PopoverColorSelector
                      colors={colors}
                      onAddCustomColor={onAddCustomColor}
                      selectedColor={form.values.measurements[index].color}
                      onChange={(color) =>
                        form.setFieldValue(`measurements.${index}.color`, color)
                      }
                    >
                      {({ color }) => (
                        <Box className={classes.indicator} bg={color} />
                      )}
                    </PopoverColorSelector>
                  </Input.Wrapper>

                  <TextInput
                    {...form.getInputProps(`measurements.${index}.label`)}
                    data-testid="spline-chart-display_name"
                    label="Display Name"
                    required
                    sx={{ flex: 1 }}
                  />

                  <TextInput
                    {...form.getInputProps(`measurements.${index}.telemetry`)}
                    data-testid="spline-chart-telemetry"
                    label="Telemetry"
                    required
                    sx={{ flex: 1 }}
                  />

                  <TextInput
                    {...form.getInputProps(`measurements.${index}.unit`)}
                    data-testid="spline-chart-unit"
                    label="Unit"
                    sx={{ flex: 0.5 }}
                  />

                  {form.values.measurements.length > 1 ? (
                    <Input.Wrapper sx={{ flexGrow: 0 }} pt="xs">
                      <Input.Label></Input.Label>

                      <ActionIcon
                        size="xs"
                        color="gray.4"
                        onClick={() =>
                          form.removeListItem('measurements', index)
                        }
                      >
                        <CloseX />
                      </ActionIcon>
                    </Input.Wrapper>
                  ) : null}
                </Group>
              ))}
            </Stack>
          </motion.div>

          <motion.div layout="position">
            <Group grow>
              <Button
                variant="default"
                onClick={() =>
                  form.insertListItem('measurements', {
                    color: 'teal_accent.4',
                    display_name: '',
                    value: '',
                    key: uuid(),
                  })
                }
              >
                Add Measurement
              </Button>
            </Group>
          </motion.div>
        </>
      ) : (
        <Stack spacing="xxl">
          <Alert
            icon={<InfoCircle width={18} height={18} />}
            title="Dynamic parameters"
            color="gray"
            styles={alertStyles}
            p="xl"
          >
            Dynamic parameters let you define where to find the measurement in
            the device configuration or details, and then have it update
            dynamically per device.{' '}
            <Anchor
              href="https://dev.xyte.io/docs/custom-widget-params"
              target="_blank"
            >
              Learn more here
            </Anchor>
          </Alert>

          <TextInput
            label="Path"
            placeholder="Example: details.video_settings[1].supported_inputs"
            required
            {...form.getInputProps('path')}
          />
        </Stack>
      )}
    </Stack>
  );
}

const alertStyles: AlertProps['styles'] = (theme) => ({
  root: {
    borderRadius: theme.radius.lg,
  },

  label: {
    fontWeight: 500,
  },

  message: {
    color: theme.colors.gray[8],
  },
});

const useStyles = createStyles((theme) => ({
  indicator: {
    width: 19,
    height: 19,
    borderRadius: theme.radius.md,
    cursor: 'pointer',
  },
}));
